import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/firm-philosophy.module.css"
import SidebarLinks from "../../components/SidebarLinks"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function Attorneys() {
  return (
    <Layout>
      <GatsbySeo
        title="Attorneys | Tecklenburg, Jenkins & Jacoby, LLC | Charleston, SC"
        description="Tecklenburg, Jenkins & Jacoby is a team of highly experienced lawyers ready to help you and your legal needs. You can reach out to any of the members of our members such as: Paul F. Tecklenburg, Rivers T. Jenkins, or Colleen E. Jacoby."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/attorneys/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <SidebarLinks />
        </section>
        <section className={styles.practiceAreas}>
          <div>
            <h1>Tecklenburg, Jenkins & Jacoby Attorneys</h1>
            <Link to="/attorneys/paul-f-tecklenburg">
              <StaticImage
                src="../../images/attorneys/pft-portrait.jpg"
                className={styles.attorneys}
                width={170}
                alt="Paul F. Tecklenburg"
              />
              <h3>Paul F. Tecklenburg</h3>
            </Link>
            <Link to="/attorneys/colleen-e-jacoby">
              <StaticImage
                src="../../images/attorneys/colleen-e-jacoby.jpg"
                width={170}
                className={styles.attorneys}
                alt="Colleen E. Jacoby"
              />
              <h3>Colleen E. Jacoby</h3>
            </Link>
          </div>
          <div>
            <Link to="/attorneys/rivers-t-jenkins">
              <StaticImage
                src="../../images/attorneys/rivers-t-jenkins.png"
                width={170}
                className={styles.rivers}
                alt="Rivers T. Jenkins III"
              />
              <h3>Rivers T. Jenkins III</h3>
            </Link>
          </div>
        </section>
      </section>
    </Layout>
  )
}
