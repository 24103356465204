import { Link } from "gatsby"
import React from "react"
import * as styles from "../styles/firm-philosophy.module.css"

export default function SidebarLinks() {
  return (
    <div className={styles.areas}>
      <ul>
        <li>
          <Link to="/practice-areas/south-carolina-admiralty-maritime-law">
            Admiralty
          </Link>
        </li>
        <li>
          <Link to="/practice-areas/south-carolina-litigation">Litigation</Link>
        </li>
        <li>
          <Link to="/practice-areas/south-carolina-business-law">
            Business Law
          </Link>
        </li>
        <li>
          <Link to="/practice-areas/south-carolina-transportation-law">
            Transportation Law
          </Link>
        </li>
        <li>
          <Link to="/practice-areas/south-carolina-real-estate-law">
            Real Estate Law
          </Link>
        </li>
        <li>
          <Link to="/practice-areas/south-carolina-construction-law">
            Construction Law
          </Link>
        </li>
        <li>
          <Link to="/practice-areas/south-carolina-environmental-law">
            Environmental Law
          </Link>
        </li>
      </ul>
    </div>
  )
}
