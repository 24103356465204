// extracted by mini-css-extract-plugin
export var areas = "firm-philosophy-module--areas--KBVIg";
export var attorneyFooter = "firm-philosophy-module--attorneyFooter--101Yw";
export var attorneyImage = "firm-philosophy-module--attorneyImage--P8M0X";
export var attorneys = "firm-philosophy-module--attorneys--ict9Y";
export var content = "firm-philosophy-module--content--aZyIe";
export var header = "firm-philosophy-module--header--xOtZy";
export var practiceAreas = "firm-philosophy-module--practiceAreas--UBxNW";
export var rivers = "firm-philosophy-module--rivers--vblE8";
export var sidebar = "firm-philosophy-module--sidebar--Xc9uS";
export var sidebarImages = "firm-philosophy-module--sidebarImages--OKPmG";